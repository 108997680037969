body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

p {
    white-space: pre;
}

body {
    text-align: center;
    max-width: 500px;
    margin: 0 auto;
    width: 100%;
}

.error {
    width: calc(100% - 40px);
    background-color: orange;
    color: white;
    padding: 20px;
}

.info {
    width: calc(100% - 40px);
    background-color: lightblue;
    color: white;
    padding: 20px;  
}

.success {
    width: calc(100% - 40px);
    background-color: lightgreen;
    color: white;
    padding: 20px;  
}

button {
    all: unset;
    cursor: pointer;
    padding: 20px;
    background-color: black;
    color: white;
    font-weight: bold;
    width: calc(100% - 50px);
    margin: 5px 0;
}
